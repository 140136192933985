<template>
  <div class="default-modal video-modal">
    <iframe
      :src="getLink(video.link)"
      allow="autoplay; encrypted-media; clipboard-write; fullscreen; picture-in-picture; screen-wake-lock;"
      class="video-modal__video"
      allowFullScreen
    ></iframe>
    <div class="video-modal__content" v-if="video.title && video.description">
      <span class="video-modal__title">{{ video.title }}</span>
      <EditorJSComponent :text="JSON.parse(video.description)" />
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "VideoModal",
  components: { EditorJSComponent },
  props: {
    video: Object,
  },
  methods: {
    getLink(link) {
      if (!link) {
        return "";
      }
      let isVK = link.includes("https://vk");
      if (isVK) {
        let results = link.match(/oid=(-?\d+).*?id=(\d+)|video(-?\d+)_(\d+)/).filter((m) => !!m);
        if (!results.length) {
          return "";
        }
        return `https://vkvideo.ru/video_ext.php?oid=${results[1]}&id=${results[2]}&hd=2&autoplay=1`;
      }
      let isRutube = link.includes("https://rutube");
      if (isRutube) {
        let results = link.match(/video\/([a-f0-9]{32})|embed\/([a-f0-9]{32})/).filter((m) => !!m);
        console.log(link, results);
        if (!results.length) {
          return "";
        }
        return `https://rutube.ru/play/embed/${results[1]}/`;
      }
      return "";
    },
  },
};
</script>

<style lang="stylus">
.video-modal {
  padding 50px
  display inline-flex
  gap 30px
  max-width 1400px
  width 100%

  &__video {
    border-radius 15px
    object-fit contain
    border none
    height 600px
    width 100%
  }

  &__content {
    display flex
    flex-direction column
    max-width 500px
    gap 30px
  }

  &__title {
    font-family var(--font-bold);
    font-style: normal;
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 41px;
    color: var(--black);
  }
}
</style>
