<template>
  <div v-if="loaded">
    <img :src="src" :alt="alt" />
    <IconComponent name="play" />
  </div>
  <LoadingIndicator title="Загрузка..." v-else />
</template>

<script>
import LoadingIndicator from "components/Loadingindicator.vue";

export default {
  name: "VideoImageComponent",
  components: { LoadingIndicator },
  props: {
    alt: String,
    link: String,
  },
  data: () => ({
    loaded: false,
    src: null,
  }),
  created() {
    this.getThumbnail().then((src) => {
      this.src = src;
      this.loaded = true;
    });
  },
  methods: {
    async getThumbnail() {
      if (!this.link) {
        return "";
      }
      let isVK = this.link.includes("https://vk");
      if (isVK) {
        let results = this.link.match(/oid=(-?\d+).*?id=(\d+)|video(-?\d+)_(\d+)/).filter((m) => !!m);
        if (!results.length) {
          return "";
        }
        let link = `https://vkvideo.ru/video${results[1]}_${results[2]}`;
        let data = await fetch(`https://vkrdownloader.xyz/server/thumb.php?vkr=${link}`).then((res) => {
          return res.json();
        });
        return data.image_data;
      }
      let isRuTube = this.link.includes("https://rutube");
      if (isRuTube) {
        let results = this.link.match(/video\/([a-f0-9]{32})|embed\/([a-f0-9]{32})/).filter((m) => !!m);
        if (!results.length) {
          return "";
        }
        return `https://rutube.ru/api/video/${results[1]}/thumbnail/?redirect=1/`;
      }
      return "";
    },
  },
};
</script>
