<template>
  <div class="page unity-page">
    <div class="unity-page__header">
      <div class="unity-page__header-gradient"></div>
      <div class="container-padding">
        <h1 class="unity-page__title">Сила V единстве</h1>
        <div class="unity-page__content">
          <EditorJSComponent :text="JSON.parse(unity.description)" />
        </div>
      </div>
    </div>
    <div class="unity-page__body">
      <div class="container-padding">
        <div class="unity-page-section" v-if="unity.images.length">
          <div class="unity-page-section__header">
            <span class="unity-page-section__title">Фотогалерея</span>
          </div>
          <div class="unity-page-section__body various-swiper">
            <SliderComponent :items="unity.images" :slider-options="imageSliderOptions" v-slot="{ item }">
              <button type="button" class="unity-page-image" @click="openImageModal(item)">
                <ImgComponent :img="item.img" />
              </button>
            </SliderComponent>
          </div>
        </div>
        <div class="unity-page-section" v-if="unity.videos.length">
          <div class="unity-page-section__header">
            <span class="unity-page-section__title">Видео</span>
          </div>
          <div class="unity-page-section__body">
            <SliderComponent :items="unity.videos" :slider-options="imageSliderOptions" v-slot="{ item }">
              <button type="button" class="unity-page-video" @click="openVideoModal(item)">
                <VideoImageComponent :link="item.link" :alt="item.title" />
              </button>
            </SliderComponent>
          </div>
        </div>
        <div class="unity-page-section" v-if="unity.news.length">
          <div class="unity-page-section__header">
            <span class="unity-page-section__title">Новости</span>
          </div>
          <div class="unity-page-section__body">
            <SliderComponent :items="unity.news" :slider-options="newsSliderOption" v-slot="{ item }">
              <NewsCardComponent :news="item" />
            </SliderComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import UNITY_PAGE from "@/graphql/pages/unity_page.graphql";
import NewsCardComponent from "components/NewsCardComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import ImageModal from "components/modals/components/ImageModal.vue";
import VideoModal from "components/modals/components/VideoModal.vue";
import VideoImageComponent from "./VideoImageComponent.vue";

export default {
  name: "UnityPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: UNITY_PAGE,
      })
      .then(({ data }) => {
        store.state.unity_page = data.unity;
      });
  },
  data() {
    return {
      imageSliderOptions: {
        slidesPerView: "auto",
        spaceBetween: 50,
      },
      unity: this.$store.state.unity_page,
      videoSliderOptions: {
        slidesPerView: 1,
      },
      newsSliderOption: {
        slidesPerView: 1.5,
        spaceBetween: 50,
        breakpoints: {
          550: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  methods: {
    openVideoModal(video) {
      this.$store.state._modals.push({
        component: VideoModal,
        options: {
          video,
        },
      });
    },
    openImageModal(image) {
      this.$store.state._modals.push({
        component: ImageModal,
        options: {
          image,
        },
      });
    },
  },
  components: {
    VideoImageComponent,
    ImgComponent,
    NewsCardComponent,
    EditorJSComponent,
    SliderComponent,
  },
  metaInfo: {
    title: "Сила V единстве",
  },
};
</script>

<style lang="stylus">
.unity-page-image {
  display inline-flex
  background none
  border-radius 15px
  border none
  cursor pointer

  img {
    width auto
    border-radius 15px
    object-fit cover
    height 300px
    +below(1024px) {
      height 200px
    }
  }
}

.unity-page-video {
  display inline-flex
  background none
  border-radius 15px
  border none
  position relative
  width 100%
  cursor pointer

  div {
    display flex
    width 100%
  }

  img {
    aspect-ratio: 16 / 9;
    border-radius 15px
    object-fit cover
  }

  .icon {
    position absolute
    left 0
    top 0
    right 0
    bottom 0
    width 50px
    height 50px
    margin auto
  }
}

.unity-page {

  &__header {
    position relative
    width 100%
    height 100%
    background-image url("/static/images/unity.webp")
    background-repeat no-repeat
    background-position center
    background-size cover
    min-height 100vh
    padding-top 140px
    display flex
    flex-direction column
    +below(1024px) {
      padding-top 70px
    }
  }

  &__header-gradient {
    position absolute
    bottom 0
    left 0
    width 100%
    height 150px
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.09) 20.15%, #FFFFFF 100%);
  }

  &__title {
    font-family var(--font-bold);
    font-weight 700
    font-size 3rem
    line-height 58px
    color: var(--dark-1);
    margin-bottom 30px
    +below(1024px) {
      margin-bottom 15px
    }
  }

  &__content {
    max-width: 818px;
    font-family: var(--font-regular);
    font-weight: 400;
    font-size: 1rem;
    line-height: 26px;
    color: var(--dark-1);
  }

  &__body {
    display flex
    flex-direction column
    padding 50px 0 145px
    +below(1024px) {
      padding 25px 0 70px
    }

    .container-padding {
      display flex
      flex-direction column
      gap 50px
      +below(1024px) {
        gap 25px
      }
    }
  }
}

.unity-page-section {
  display flex
  flex-direction column
  gap 30px
  +below(1024px) {
    gap 15px
  }


  &__header {
    display flex
    align-items center
  }

  &__title {
    font-family var(--font-bold);
    font-weight 700
    font-size 3rem
    line-height 58px
    color: var(--dark-1);
    +below(1024px) {
      font-size 1.5rem
    }
  }

  &__body {

    .slider-arrows__item {
      &--left {
        left -60px
      }

      &--right {
        right -60px
      }

      .icon {
        width 14px
        height 14px
      }
    }
  }

  .various-swiper .swiper-slide {
    width auto !important
  }
}
</style>
