<template>
  <div class="default-modal image-modal">
    <ImgComponent :img="image.img" class="image-modal__image" />
    <div class="image-modal__content" v-if="image.title && image.description">
      <span class="image-modal__title">{{ image.title }}</span>
      <EditorJSComponent :text="JSON.parse(image.description)" />
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "ImageModal",
  components: { EditorJSComponent, ImgComponent },
  props: {
    image: Object,
  },
};
</script>

<style lang="stylus">
.image-modal {
  padding 50px
  display inline-flex
  gap 30px
  flex-direction row
  align-items flex-start
  max-width 1400px
  width auto

  &__image {
    border-radius 15px
    object-fit contain
    max-width 800px
    max-height 600px
    height max-content
    width max-content
  }

  &__content {
    display flex
    flex-direction column
    max-width 500px
    gap 30px
  }

  &__title {
    font-family var(--font-bold);
    font-style: normal;
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 41px;
    color: var(--black);
  }
}
</style>
